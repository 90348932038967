import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as SudokuActions from '../../redux/actions/sudoku';

import ResponsiveSquare from '../common/ResponsiveSquare';
import SudokuCell from './SudokuCell';

const SudokuBoard = ({ sudokuId }) => {
    const dispatch = useDispatch();

    const sudokuCells = useSelector(state => state.sudoku.byId[sudokuId].cells);
    const activeCell = useSelector(state => state.sudoku.byId[sudokuId].selectedIdx);

    const onClickCell = (idx) => {
        dispatch(SudokuActions.selectCell(sudokuId, idx));
    }

    const renderRows = () => {
        const rows = [];

        for(let i=0; i<9; i++) {
            rows.push(
                <div key={i} className={`flex-1 flex flex-row min-h-0`}>
                    {renderCells(i)}
                </div>
            );
        }

        return rows;
    }
    
    const renderCells = (row) => {
        const cells = [];
        const startCellIdx = row*9;

        for(let i=startCellIdx; i<startCellIdx+9; i++) {
            const cell = sudokuCells[i];
            cells.push(
                <SudokuCell 
                    value={cell.isPrefilled ? cell.answer : cell.guess} 
                    cellIndex={i} 
                    isPrefilled={cell.isPrefilled}
                    key={i} 
                    onCellClicked={() => onClickCell(i)}
                    isActive={activeCell === i}
                    isChecked={cell.isChecked}
                    isCorrect={cell.isCorrect}
                    notes={cell.notes}
                    hSep={i !== startCellIdx && i % 3 === 0} 
                    vSep={row !== 0 && row % 3 === 0 }
                />
            );
        }

        return cells;
    }

    return (
        <ResponsiveSquare>
            <div className="rounded border-black border-4 w-full h-full flex flex-col select-none">
                {renderRows()}
            </div>
        </ResponsiveSquare>
    );
}

export default SudokuBoard;
