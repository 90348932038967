import React from 'react';
import { Helmet } from 'react-helmet';

const Memoria = () => {
    return (
        <>
        <Helmet>
            <title>Memoria Dr. Zoilo Manuel Fernández Coste - TuRecreo</title>
        </Helmet>
        <div className="p-8 text-center">
            <h1 className="text-3xl pb-2 font-bold text-gray-900">
                Memoria
            </h1>
            <h3 className="text-lg font-medium text-gray-500">
                Dr. Zoilo Manuel Fernández Coste
            </h3>
        </div>
            
        <div className="p-5">
            <div className="flex flex-col md:flex-row">
                <img className="object-scale-down md:mr-4" alt="" src="/static/img/drzoilo.png" />
                <div>
                    <p className="py-2">
                    Esta página de Internet va dedicada a mi adorado e inolvidable padre el Dr. Zoilo Manuel Fernández Coste 
                    (14 Mayo 1936 - 30 Abril 2015)
                    </p>
                    <p className="py-2">
                    Mi padre me enseñó y motivó a empezar hacer crucigramas a la edad de 10 años y este esfuerzo está dedicado a su memoria, ya que le encantaban hacerlos.
                    </p>
                    <p className="py-2">
                    Mi padre ha sido el hombre más inteligente que he conocido, fue a Canadá (Parte Inglesa) hacer una especialidad sin saber inglés y aprendió solo, inclusive fue profesor en la Universidad de Loyola, Chicago, EUA sin haber asistido nunca a una clase de inglés.
                    </p>
                    <p className="py-2">
                    Después de unos años, fue a Estados Unidos y luego volvió a Canadá (Parte Francesa) y aprendió francés solo sin haber ido nunca a una escuela.
                    </p>
                    <p className="py-2">
                    Mi padre escribio un libro de semejanzas y diferencias entre la Biblia y el Coran, el cual nunca publicó, porque no quiso tomar el riesgo de que lo islámicos malinterprataran algo y tomaran represalia contra su familia.
                    </p>
                    <p className="py-2">
                    Tuvo 4 hijos: Kico, Juan Manuel, Delba Raquel y Zoilo Manuel. Sus nietos: Delba Cristina, Juan Manuel, Juan Eugenio, Mari Jo, Ana María, Jose Felipe y Evelina.  Sus biznietos:  Sophia, Julian Felipe y Niclao.  Hijo adoptivo: Douglas.  Nietos políticos:  Juan Antonio, Sara y Salvador.
                    </p>
                </div>
            </div>
            
            <h3 className="text-lg font-medium text-gray-700 mt-4 mb-1">Logros</h3>
            <div className="flex flex-col md:flex-row">
                <p className="py-2 flex-1 md:mr-8 text-justify">
                    <p className="pb-2">
                        Se graduó de médico en la Universidad de Santo Domingo en el 1960 y le dedico más de 15 años de entrenamiento entre la carrera y las especialidades, entre los cuales tenemos:
                    </p>
                    <ol className="list-oustide list-disc">
                        <li>Hospital Padre Billini – Pasantía 1960 – 61</li>
                        <li>Ottawa General Hospital, Ottawa, Canada 1961 – 63, Residencia Cirugía General</li>
                        <li>Trumbull Memorial Hospital, Warren, Ohio, Usa 1963- 64 Residencia Cirugía General</li>
                        <li>Baptist Hospital, Nashville, Tenn, Usa 1964- 65 Residencia Cirugía General</li>
                        <li>Veteran Adm. Hospital, Hines, Ill. Usa, 1965 – 67, Residencia Cirugía General, Torácica y Cardiovascular.</li>
                        <li>Institute of cardiology, Montreal, Canada 1970 – 71, Residencia Cirugía Cardio-Vascular</li>
                    </ol>
                </p>
                <p className="py-2 flex-1 md:ml-8 text-justify">
                    <p className="pb-2">
                        Entre sus principales logros, podemos citar:
                        </p>
                    <ol className="list-oustide list-disc">
                        <li>Profesor Cirugía – Universidad de Loyola, Chicago, Ill., Usa 1965 – 67</li>
                        <li>Miembro Fundador y Vicepresidente ¨C. B. Puestow Surgical Society¨  Chicago, Ill., Usa</li>
                        <li>Profesor (Cátedra) Cirugía Torácica y Cardio-Vascular – Universidad INTEC</li>
                        <li>Miembro Fundador ¨Colegio Dominicano de Cirujanos¨</li>
                        <li>Miembro Fundador Residencia Cirugia Gral. Hospital Salvador Gautier</li>
                        <li>Ayudante Especial Cirugía Gral  y Cardio-Vascular Hospital Salvador Gautier 1967 – 2004</li>
                        <li>Diplomado ECFMG – USA 1963</li>
                        <li>Clínica Abel González - Cirujano Cardio Vascular y General 1968 - 1996</li>
                        <li>Clínica Independencia – Cirujano General 1996-2010</li>
                    </ol>
                </p>
            </div>
            <h3 className="text-lg font-medium text-gray-700 mt-4 mb-1">Familia</h3>
            <div className="flex flex-col md:flex-row py-2">
                <p className="flex-1 md:mr-4">
                    Aquí está mi padre sentado y están de pie de derecha a izquierda mi hija querida Delba Cristina, yo, mi adorada madre Delba Altagracia, mi hijo querido Juan Manuel y la esposa de mi Padre Carmen Méndez, con quien estuvo casado hasta su deceso después del divorcio con mi madre y goza de todo nuestro aprecio y consideración.
                    <img className="mr-auto ml-auto pt-4" alt="" src="/static/img/dzfamily.jpg" />
                </p>
                <p className="flex-1 md:ml-4">
                    Su hija Raquel con los nietos Juan Eugenio, Mari Jo y Ana Maria y la biznieta Sophia.
                    <img className="mr-auto ml-auto pt-4" alt="" src="/static/img/dzfamily_1.jpg" />
                </p>
            </div>
            <div className="flex flex-col md:flex-row py-2">
                <p className="flex-1 md:mr-2">
                    Sus hijos Kico, Juan Manuel y el nieto Juan Manuel
                    <img className="mr-auto ml-auto pt-4" alt="" src="/static/img/dzfamily_3.jpg" />
                </p>
                <p className="flex-1 md:mx-2">
                    Los nietos: Jose Felipe y Avelina y los biznietos Jose Felipe y Niclao.
                    <img className="mr-auto ml-auto pt-4" alt="" src="/static/img/dzfamily_2.png" />
                </p>
                
                <p className="flex-1 md:ml-2">
                    Su hijo Zoilo Manuel, su esposa Carmen y Don Zoilo
                    <img className="mr-auto ml-auto pt-4" alt="" src="/static/img/dzfamily_4.jpg" />
                </p>
            </div>
            
        </div>
        <div className="flex">
            <div className="flex-1">
            </div>
        </div>
        </>
    )
}

export default Memoria;