import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['crossword', 'sudoku']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [
    applyMiddleware(thunk),
]

if(process.env.NODE_ENV === 'development') {
    middlewares.push(applyMiddleware(logger));
}

export const store = createStore(
    persistedReducer,
    compose(
        ...middlewares
    )
);

export const persistor = persistStore(store);