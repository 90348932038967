import React from 'react';

const ResponsiveSquare = ({ children }) => {
    if(!children) return null;

    return (
        <div className="square">
            <div className="square-content">
                { children }
            </div>
        </div>
    );
}

export default ResponsiveSquare;