import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es'; 

import './App.css';
import "./tailwind.generated.css";
import "react-datetime/css/react-datetime.css";
import 'react-toastify/dist/ReactToastify.css';

import Admin from './routes/Admin/index';
import NotFound from './routes/NotFound';
import Crossword from './routes/Crossword';
import Memoria from './routes/Memoria';
import Sudoku from './routes/Sudoku';

import * as AdminActions from './redux/actions/admin';

moment.locale('es');

function App() {
  const dispatch = useDispatch();
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false)
  const isLoggedIn = useSelector(state => state.admin.isLoggedIn);

  useEffect(() => {
    dispatch(AdminActions.checkAuth());
  }, [dispatch]);

  const onClickLogout = () => {
    dispatch(AdminActions.logout());
  }

  const onClickMobileMenu = () => {
    setMobileMenuOpened(current => !current);
  }

  const renderMenu = (isMobile) => {
    return (
      <>
      <Link to="/" className={`px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 ${isMobile ? 'block' : ''}`}>Crucigramas</Link>
      <Link to="/sudoku" className={`px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 ${isMobile ? 'block' : ''}`}>Sudoku</Link>
      <Link to="/memoria" className={`px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 ${isMobile ? 'block' : ''}`}>Memoria</Link>
      {isLoggedIn ? 
        <>
        <Link to="/admin" className={`px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 ${isMobile ? 'block' : ''}`}>Admin</Link>
        <button onClick={onClickLogout} className={`px-3 inline-flex py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 ${isMobile ? 'block' : ''}`}> 
          Cerrar sesión
          <svg className="ml-2 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
        </button> 
        </>
        : null}
      </>
    );
    
  }

  return (
    <div>
      <Router>
        <nav className="bg-gray-800 shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex-row flex items-center">
            <h1 className="text-2xl font-bold leading-tight text-white flex-grow">
              <Link to="/">tuRecreo.com</Link>
            </h1>

            <div className="hidden md:block">
              {renderMenu()}
            </div>
            
              <div className="-mr-2 flex md:hidden">
                <button onClick={onClickMobileMenu} className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">Open main menu</span>

                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    {mobileMenuOpened ?
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    :
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    }
                  </svg>

                </button>
              </div>
          </div>
          <div className={`${mobileMenuOpened ? 'block' : 'hidden'} md:hidden`}>
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {renderMenu(true)}
            </div>
          </div>
        </nav>
      
        <div className="container m-auto p-2">
          <Switch>
            <Route exact path="/">
              <Crossword />
            </Route>
            <Route exact path="/sudoku">
              <Sudoku />
            </Route>
            <Route exact path="/memoria">
              <Memoria />
            </Route>
            <Route path="/admin">
              <Admin/>
            </Route>
            <Route path="/crucigramas/:slug">
              <Crossword />
            </Route>
            <Route path="/sudoku/:slug">
              <Sudoku />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </Router>

      <div className="my-16 text-center text-gray-500">
              Copyright &copy; {new Date().getFullYear()} TuRecreo. Todos los derechos reservados.
      </div>
  
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
