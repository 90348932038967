import { toast } from 'react-toastify';

import * as types from './actionTypes';

import * as adminService from '../../services/admin.service';
import * as authService from '../../services/auth.service';

export const fetchPuzzles = (puzzleType) => {
    return async (dispatch) => {
        dispatch({type: types.ADMIN_SET_LOADING_LIST});

        const puzzles = await adminService.getPuzzles(puzzleType);

        dispatch({type: types.ADMIN_SET_LIST, payload: puzzles});
    }
}

export const uploadPuzzle = (puzzleType, formData) => {
    return async () => {
        try {
            await adminService.createPuzzle(puzzleType, formData);
            return true;

        } catch(errorMsg) {
            toast(errorMsg, {type: "error"});
            console.error("Error on upload:", errorMsg);
            return false;
        }
    }
}

export const deletePuzzle = (puzzleType, puzzleId) => {
    return async (dispatch) => {
        try {
            await adminService.deletePuzzle(puzzleId);
            toast("Eliminado exitosamente", {type: "success"});
            dispatch(fetchPuzzles(puzzleType));

        } catch(errorMsg) {
            toast(errorMsg, {type: "error"});
            console.error("Error on deleting puzzle:", errorMsg);
        }
    }
}
export const authenticate = (username, password) => {
    return async (dispatch) => {
        const user = await authService.login(username, password);
        dispatch({type: types.ADMIN_SET_USER, payload: { user }});
    }
}

export const checkAuth = () => {
    return async (dispatch) => {
        const user = await authService.checkToken();
        dispatch({type: types.ADMIN_SET_USER, payload: { user }});
    }
}

export const logout = () => {
    return (dispatch) => {
        authService.logout();
        dispatch({type: types.ADMIN_SET_USER, payload: {}});
    }
}