import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as SudokuActions from '../../redux/actions/sudoku';

const SudokuKeyboard = ({ sudokuId }) => {
    const dispatch = useDispatch();
    const pencilMode = useSelector(state => state.sudoku.pencilMode);

    const onClickNumber = (number) => {
        dispatch(SudokuActions.updateCurrentCell(sudokuId, number));
    }

    const onClickDirection = (direction) => {
        dispatch(SudokuActions.moveSelectedCell(sudokuId, direction));
    }

    return (
        <>
            <div className="flex w-full h-full flex-row md:flex-col my-2 md:max-w-300" >
                <div className="flex flex-2 md:flex-1 flex-col text-base md:text-2xl">
                    <div className="flex flex-1">
                        <button onClick={() => onClickNumber(7)} className="flex-1 bg-gray-200 text-center m-1 p-4 font-medium rounded">
                            7
                        </button>
                        <button onClick={() => onClickNumber(8)} className="flex-1 bg-gray-200 text-center m-1 p-4 font-medium rounded">
                            8
                        </button>
                        <button onClick={() => onClickNumber(9)} className="flex-1 bg-gray-200 text-center m-1 p-4 font-medium rounded">
                            9
                        </button>
                    </div>
                    <div className="flex flex-1">
                        <button onClick={() => onClickNumber(4)} className="flex-1 bg-gray-200 text-center m-1 p-4 font-medium rounded">
                            4
                        </button>
                        <button onClick={() => onClickNumber(5)} className="flex-1 bg-gray-200 text-center m-1 p-4 font-medium rounded">
                            5
                        </button>
                        <button onClick={() => onClickNumber(6)} className="flex-1 bg-gray-200 text-center m-1 p-4 font-medium rounded">
                            6
                        </button>
                    </div>
                    <div className="flex flex-1">
                        <button onClick={() => onClickNumber(1)} className="flex-1 bg-gray-200 text-center m-1 p-4 font-medium rounded">
                            1
                        </button>
                        <button onClick={() => onClickNumber(2)} className="flex-1 bg-gray-200 text-center m-1 p-4 font-medium rounded">
                            2
                        </button>
                        <button onClick={() => onClickNumber(3)} className="flex-1 bg-gray-200 text-center m-1 p-4 font-medium rounded">
                            3
                        </button>
                    </div>
                    <div className="flex flex-1">
                        <div className={`flex-1 ${pencilMode ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} text-center m-1 p-4 font-medium rounded flex items-center justify-center flex-col`}>
                            <svg className="ml-1 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
                            </svg>
                        </div>
                        <button onClick={() => onClickNumber()} className="flex-2 bg-gray-200 flex items-center justify-center m-1 p-4 font-medium rounded">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="grid flex-1 grid-cols-2 gap-1 text-center my-1 md:my-4 mx-4 md:mx-10">
                    <button onClick={() => onClickDirection("UP")} className="col-start-1 col-span-2 bg-gray-200 p-4 rounded">🔼</button>
                    <button onClick={() => onClickDirection("LEFT")} className="bg-gray-200 p-4 rounded">◀️</button>
                    <button onClick={() => onClickDirection("RIGHT")} className="bg-gray-200 p-4 rounded">▶️</button>
                    <button onClick={() => onClickDirection("DOWN")} className="col-start-1 col-span-2 bg-gray-200 p-4 rounded">🔽</button>
                </div>
            </div>
        </>
    );
}

export default SudokuKeyboard;