import React from 'react';

const CrosswordCell = ({ number, value, isBlank, isActive, isChecked, isSelected, isRevealed, onCellClicked }) => {

    if(isBlank) {
        return <div/>;
    }

    return (
        <div
         onClick={onCellClicked}
         className={`relative flex justify-center items-center uppercase rounded-sm ${isActive ? "bg-green-200" : isSelected ? "bg-blue-100" : "bg-white"}`} >
            {number ? 
                <div className="absolute top-0 left-0.5 sm:top-0.5 sm:left-1 select-none text-xxxs sm:text-xxs">
                    {number}
                </div> 
            : null}
            {value ? 
                <div className="select-none text-xs xl:text-xl sm:text-base text-center pt-2 font-semibold uppercase flex-1" style={{color: isRevealed ? '#2157BB' : isChecked ? 'red' : undefined }}>
                    {value}
                </div>
            : null}
        </div>
    );
}

export default CrosswordCell;