import React from 'react';
import { useDispatch } from 'react-redux';

import * as SudokuActions from '../../redux/actions/sudoku';

const SudokuToolbar = ({ sudokuId }) => {
    const dispatch = useDispatch();

    const onClickCheck = () => {
        dispatch(SudokuActions.checkAnswer(sudokuId));
    }

    return (
        <div className="mt-1 bg-gray-100 shadow rounded-sm px-5 flex flex-row items-center mb-2 sm:mb-4 justify-between">
            <div className="flex-grow text-gary-400">
                <span className="md:block hidden text-xl">
                    ¡Nuevos Sudokus todos los dias!
                </span>
            </div>
            <div className="flex flex-row">
                <div>
                    <button type="button" onClick={() => dispatch(SudokuActions.clear(sudokuId))} className={`rounded my-1 inline-flex justify-center py-2 px-4 text-xl items-center font-medium hover:bg-gray-50 focus:outline-none focus:ring-none`}>
                    Reiniciar
                    <svg className="ml-1 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
                    </svg>
                    </button>
                </div>
                <div>
                    <button type="button" onClick={onClickCheck} className={`rounded my-1 inline-flex justify-center py-2 px-4 text-xl items-center font-medium hover:bg-gray-50 focus:outline-none focus:ring-none`}>
                    Comprobar
                    <svg className="ml-1 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    </button>
                </div>
            
            </div>
        </div>
    );
}

export default SudokuToolbar;