import React, { useEffect, useState, useRef, createRef } from 'react';
import { useDispatch } from 'react-redux';

import { selectHint } from '../../redux/actions/crossword';

const HintList = ({title, hints, hintType, isDirectionActive, selectedIdx, crosswordId}) => {
    const dispatch = useDispatch();

    const [elRefs, setElRefs]= useState([]);
    const scrollerRef = useRef();

    const onClickHint = (idx) => {
        dispatch(selectHint(crosswordId, hintType, idx));
    }

    const hintCount = hints.length;
    useEffect(() => {
        setElRefs(elRefs => (
            Array(hintCount).fill().map((_, i) => elRefs[i] || createRef())
          ));
    }, [hintCount]);

    useEffect(() => {
        if(!selectedIdx) return;
        if(selectedIdx >= elRefs.length || !scrollerRef) return;

        const listItem = elRefs[selectedIdx].current;
        const listContainer = scrollerRef.current;

        const listItemCenterPosition = listItem.offsetTop - listContainer.getBoundingClientRect().height;
        listContainer.scrollTo({
            top: listItemCenterPosition, 
            behavior: 'smooth'
        })

    }, [selectedIdx, scrollerRef, elRefs])

    return (
        <div className="p-2 pt-0 flex-1 flex flex-col min-h-0">
            <h3 className="font-semibold pb-2">{title}</h3>
            <div ref={scrollerRef} className="overflow-y-scroll flex-grow">
            {hints.map((c, idx) => 
                <div 
                    key={hintType + c.number} 
                    ref={elRefs[idx]} 
                    className={`flex p-1 select-none cursor-pointer ${isDirectionActive && selectedIdx === idx ? "bg-blue-100" : selectedIdx === idx ? "bg-gray-100" : "hover:bg-blue-50" } `}
                    onClick={() => onClickHint(idx)}>
                    <div className="flex-1 text-right mr-2 font-bold">{c.number}</div>
                    <div style={{flex: 12}}>{c.hint}</div>
                </div>
            )}
            </div>
        </div>
    );
}

export default HintList;