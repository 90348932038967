import React, {useState} from 'react';

const Dropdown = ({ title, items }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOverOptions, setIsOverOptions] = useState(false);

    const onClickButton = () => {
        setIsOpen(current => !current);
    }

    const onFocusLeave = () => {
        if(!isOverOptions) setIsOpen(false);
    }

    const onOptionClicked = (callback) => {
        if(callback) callback();
        setIsOpen(false);
    }

    const onMouseEnterOptions = () => {
        setIsOverOptions(true);
    }

    const onMouseLeaveOptions = () => {
        setIsOverOptions(false);
        setIsOpen(false);
    }

    return (
        <div className="relative inline-block text-left">
            <div className="">
                <button type="button" onBlur={onFocusLeave} onClick={onClickButton} className={`${isOpen ? "bg-white  border-gray-300 shadow-sm  text-gray-700" : ""} items-center inline-flex justify-center py-2 px-4 text-lg font-medium hover:bg-gray-50 focus:outline-none focus:ring-none`} aria-haspopup="true" aria-expanded={isOpen}>
                {title}
                <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
                </button>
            </div>

            {isOpen ? 
            <div onMouseEnter={onMouseEnterOptions} onMouseLeave={onMouseLeaveOptions} className="z-50 origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div className="py-1" role="menu" aria-orientation="vertical">
                    {items && items.map((item, i) => (
                        <div key={i} onClick={() => onOptionClicked(item.onClicked)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer" role="menuitem">{item.title}</div>
                    ))}
                </div>
            </div>
            : null }
        </div>
    );
}

export default Dropdown;

