import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import * as CrosswordActions from '../../redux/actions/crossword';

import CrosswordBoard from './CrosswordBoard';
import CrosswordHintBar from './CrosswordHintBar';
import CrosswordToolbar from './CrosswordToolbar';
import HintList from './HintList';
import Modal from '../Modal';
import useTouchDevice from '../../hooks/useTouchDevice';

const Crossword = ({ crosswordId }) => {
    const dispatch = useDispatch();
    const [showDoneModal, setShowDoneModal] = useState(false); 
    const isTouchDevice = useTouchDevice();

    const isDone = useSelector(state => state.crossword.byId[crosswordId].isDone);
    const hints = useSelector(state => state.crossword.byId[crosswordId].hints);
    const selectedIdx = useSelector(state => state.crossword.byId[crosswordId].selectedIdx);
    const selectHorizontal = useSelector(state => state.crossword.byId[crosswordId].selectHorizontal);
    const selectedHorizontalHintIdx = useSelector(state => state.crossword.byId[crosswordId].selectedHorizontalHintIdx);
    const selectedVerticalHintIdx = useSelector(state => state.crossword.byId[crosswordId].selectedVerticalHintIdx);
    
    useEffect(() => {
        setShowDoneModal(isDone);
    }, [isDone]);

    const onKeydownEvent = useCallback((ev) => {
        if(ev.key === 'Enter') {
            dispatch(CrosswordActions.selectNextHint(crosswordId, selectedIdx));
            ev.preventDefault();
        } else if(ev.key === 'Tab') {
            dispatch(CrosswordActions.toggleDirection(crosswordId));
            ev.preventDefault();
        } else if(ev.key === 'ArrowUp') {
            dispatch(CrosswordActions.selectPreviousCell(crosswordId, undefined, false));
            ev.preventDefault();
        } else if(ev.key === 'ArrowDown') {
            dispatch(CrosswordActions.selectNextCell(crosswordId, undefined, [], false, true));
            ev.preventDefault();
        } else if(ev.key === 'ArrowRight') {
            dispatch(CrosswordActions.selectNextCell(crosswordId, undefined, [], true, true));
            ev.preventDefault();
        } else if(ev.key === 'ArrowLeft') {
            dispatch(CrosswordActions.selectPreviousCell(crosswordId, undefined, true));
            ev.preventDefault();
        } else if(ev.key === 'Backspace' || ev.key === 'Delete') {
            dispatch(CrosswordActions.pressBackspace(crosswordId));
        } else if(/^[aA-zZñÑ]$/.test(ev.key)) {
            // letter
            dispatch(CrosswordActions.updateCurrentCell(crosswordId, ev.key));
            dispatch(CrosswordActions.selectNextCell(crosswordId));
            ev.preventDefault();
        } 
    }, [dispatch, crosswordId, selectedIdx]);

    const onFakeKeyPress = (key) => {
        onKeydownEvent({ key: key.replace('{', '').replace('}', ''), preventDefault: () => {} });
    }

    useEffect(() => {
        document.body.addEventListener('keydown', onKeydownEvent);

        return () => {
            document.body.removeEventListener('keydown', onKeydownEvent);
        }
    }, [onKeydownEvent]);

    const onPressCloseModal = () => {
        setShowDoneModal(false);
    }

    return (
        <>
        <Modal onClickCancel={onPressCloseModal} show={showDoneModal} variant="success" title="¡Felicidades!" description="¡Ha completado el crucigrama con exito!" cancelText="Cerrar" />
        <div className="md:h-screen flex flex-col md:max-h-screen lg:max-h-700">
            <CrosswordToolbar crosswordId={crosswordId} />
            <div className="flex space-x-3 flex-col md:flex-row flex-grow min-h-0">
                <div className="flex-2 lg:flex-1">
                    <CrosswordBoard crosswordId={crosswordId} />
                    
                    {isTouchDevice ? 
                    <div>
                        <CrosswordHintBar crosswordId={crosswordId} />
                        <div className="flex flex-row">
                            <div className="flex-3">
                                <Keyboard 
                                    theme="hg-theme-default hg-theme-highlight"
                                    baseClass="keyboard-main"
                                    onKeyPress={onFakeKeyPress}
                                    layout={{default:[
                                        'Q W E R T Y U I O P {ArrowUp}',
                                        'A S D F G H J K L Ñ {ArrowLeft} {ArrowRight}',
                                        'Z X C V B N M {Backspace} {Tab} {Enter} {ArrowDown}',
                                    ]}} display={{
                                        '{Backspace}': 'Borrar',
                                        '{ArrowUp}': '🔼',
                                        '{ArrowDown}': '🔽',
                                        '{ArrowLeft}': '◀️',
                                        '{ArrowRight}': '▶️',
                                        '{Tab}': 'Tab',
                                        '{Enter}': 'Enter'
                                    }}  
                                /> 
                            </div>
                        </div>
                          
                    </div>   
                    : null }      

                </div>
                <div className="flex-1">
                    <div className="flex flex-col lg:flex-row box-border h-full max-h-300 md:max-h-full mt-5 md:mt-0">
                        <HintList 
                            title="Horizontal" 
                            hintType="HORIZONTAL" 
                            crosswordId={crosswordId}
                            isDirectionActive={selectHorizontal} 
                            selectedIdx={selectedHorizontalHintIdx}
                            hints={hints.horizontal} 
                        />
                        <HintList 
                            title="Vertical" 
                            hintType="VERTICAL" 
                            crosswordId={crosswordId}
                            isDirectionActive={!selectHorizontal} 
                            selectedIdx={selectedVerticalHintIdx} 
                            hints={hints.vertical} 
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Crossword;