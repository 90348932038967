import * as types from './actionTypes';

import * as puzzleService from '../../services/puzzle.service';

export const fetchLatest = () => {
    return async (dispatch, getState) => {
        dispatch({type: types.SUDOKU_SET_LOADING});

        const latestData = await puzzleService.getLatest("sudoku");
        const sudoku = latestData.sudoku;

        if(!getState().sudoku.byId[sudoku._id]) {
            dispatch({type: types.SUDOKU_SET_DATA, payload: sudoku});
            dispatch(selectCell(sudoku._id, 0));
        }
        
        dispatch({type: types.SUDOKU_SET_RELATED, payload: latestData.related});

        return sudoku;
    }
}

export const fetchBySlug = (slug) => {
    return async (dispatch, getState) => {
        dispatch({type: types.SUDOKU_SET_LOADING});

        const resData = await puzzleService.getBySlug("sudoku", slug);
        const sudoku = resData.sudoku;

        if(!sudoku) return null;

        if(!getState().sudoku.byId[sudoku._id]) {
            dispatch({type: types.SUDOKU_SET_DATA, payload: sudoku});
            dispatch(selectCell(sudoku._id, 0));
        }

        dispatch({type: types.SUDOKU_SET_RELATED, payload: resData.related});
        
        return sudoku;
    }
}

export const selectCell = (sudokuId, cellIdx) => {
    return (dispatch, getState) => {
        const { sudoku: { byId: { [sudokuId]: { cells } } } } = getState();

        if(cellIdx < 0 || cellIdx >= cells.length) return;

        if(cells[cellIdx].isPrefilled) return;

        dispatch({
            sudokuId,
            type: types.SUDOKU_SELECT, 
            payload: {
                selectedIdx: cellIdx
            }
        });
    }
}

export const updateCurrentCell = (sudokuId, value) => {
    return (dispatch, getState) => {
        const { sudoku: { pencilMode, byId: { [sudokuId]: { isDone, isLost, selectedIdx, cells } } } } = getState();

        if(isDone || isLost) return;

        if(cells[selectedIdx].isRevealed) return;

        let newPencilMode = pencilMode;
        if(cells[selectedIdx].guess && !pencilMode) {
            dispatch(togglePencilMode(sudokuId));
            newPencilMode = true;
        }

        if(newPencilMode && value) {
            return dispatch(togglePencilled(sudokuId, value));
        }

        dispatch({
            type: types.SUDOKU_SET_CELL,
            sudokuId,
            payload: {
                value: value && parseInt(value),
                idx: selectedIdx
            }
        });

        const { sudoku: { byId: { [sudokuId]: { cells: newCells } } } } = getState();
        if(newCells.filter(c => c.guess || c.isPrefilled).length === newCells.length) {
            dispatch(checkAnswer(sudokuId, true));
        }
    }
}

export const togglePencilMode = (sudokuId) => {
    return (dispatch, getState) => {
        const { sudoku: { byId: { [sudokuId]: { selectedIdx, cells } } } } = getState();

        const currentCell = cells[selectedIdx];
        const activeNotes = (currentCell.notes || []).reduce((acc, val, idx) => {
            if(val === true) {
                acc.push(idx+1);
            }

            return acc;
        }, []);

        dispatch({
            type: types.SUDOKU_TOGGLE_PENCIL
        });

        const { sudoku: { pencilMode } } = getState();

        if(!pencilMode && activeNotes.length === 1) {
            dispatch(updateCurrentCell(sudokuId, activeNotes[0]));
        }

    }
    
}

export const togglePencilled = (sudokuId, number) => {
    return (dispatch, getState) => {
        const { sudoku: { byId: { [sudokuId]: { selectedIdx, cells } } } } = getState();

        if(cells[selectedIdx].isPrefilled) return;

        dispatch({
            type: types.SUDOKU_TOGGLE_CELL_NOTE,
            sudokuId,
            payload: {
                number,
                idx: selectedIdx
            }
        });

        const { sudoku: { pencilMode, byId: { [sudokuId]: { cells : newCells } } } } = getState();

        const currentCell = newCells[selectedIdx];
        const activeNotes = (currentCell.notes || []).reduce((acc, val, idx) => {
            if(val === true) {
                acc.push(idx+1);
            }

            return acc;
        }, []);

        if(activeNotes.length === 1 && pencilMode) {
            dispatch(togglePencilMode(sudokuId));
        }
    }
}

export const moveSelectedCell = (sudokuId, direction, currentCellIdx) => {
    return (dispatch, getState) => {
        const { sudoku: { byId: { [sudokuId]: { selectedIdx, cells, sudokuSize } } } } = getState();

        let nextIdx = currentCellIdx === undefined ? selectedIdx : currentCellIdx;
        switch (direction) {
            case "RIGHT":
                nextIdx++;
                break;
            case "LEFT":
                nextIdx--;
                break;
            case "UP":
                nextIdx -= sudokuSize;
                break;
            case "DOWN":
                nextIdx += sudokuSize;
                break;
            default:
                return;
        }

        if(nextIdx < 0 || nextIdx >= cells.length) return;

        if(cells[nextIdx].isPrefilled) {
            return dispatch(moveSelectedCell(sudokuId, direction, nextIdx));
        }

        dispatch(selectCell(sudokuId, nextIdx));
    }
}

export const clear = (sudokuId) => {
    return {
        type: types.SUDOKU_CLEAR_PROGRESS,
        sudokuId
    }
}

export const checkAnswer = (sudokuId, noFail=false) => {
    return (dispatch) => {
        dispatch({
            type: types.SUDOKU_CHECK,
            sudokuId,
            payload: {
                noFail
            }
        });

    }
}
