import * as types from '../actions/actionTypes';

const initialState = {
    isLoading: true,
    isLoggedIn: false,
    puzzles: []
};

const AdminReducer = (state = initialState, action) => {
    switch(action.type) {
        case types.ADMIN_SET_LOADING_LIST:
            return {
                ...state,
                isLoading: true
            };
        case types.ADMIN_SET_LIST: 
            return {
                ...state,
                isLoading: false,
                puzzles: action.payload
            };
        case types.ADMIN_SET_USER: 
            return {
                ...state,
                isLoggedIn: Boolean(action.payload.user),
                user: action.payload.user
            }
        default:
            return state;
    }
}

export default AdminReducer;