import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as SudokuActions from '../../redux/actions/sudoku';

import SudokuBoard from './SudokuBoard';
import SudokuKeyboard from './SudokuKeyboard';
import Modal from '../Modal';
import SudokuToolbar from './SudokuToolbar';

const Sudoku = ({ sudokuId }) => {
    const dispatch = useDispatch();
    const [showLostModal, setShowLostModal] = useState(false);
    const [showDoneModal, setShowDoneModal] = useState(false); 

    const isDone = useSelector(state => state.sudoku.byId[sudokuId].isDone);
    const isLost = useSelector(state => state.sudoku.byId[sudokuId].isLost);
    const isPuzzleChecked = useSelector(state => state.sudoku.byId[sudokuId].isPuzzleChecked);

    useEffect(() => {
        setShowDoneModal(isDone && isPuzzleChecked && !isLost);
    }, [isDone, isLost, isPuzzleChecked]);

    useEffect(() => {
        setShowLostModal(isLost);
    }, [isLost]);

    const onKeydownEvent = useCallback((ev) => {
        if(ev.key === 'ArrowUp') {
            dispatch(SudokuActions.moveSelectedCell(sudokuId, "UP"));
            ev.preventDefault();
        } else if(ev.key === 'ArrowDown') {
            dispatch(SudokuActions.moveSelectedCell(sudokuId, "DOWN"));
            ev.preventDefault();
        } else if(ev.key === 'ArrowRight') {
            dispatch(SudokuActions.moveSelectedCell(sudokuId, "RIGHT"));
            ev.preventDefault();
        } else if(ev.key === 'ArrowLeft') {
            dispatch(SudokuActions.moveSelectedCell(sudokuId, "LEFT"));
            ev.preventDefault();
        } else if(ev.key === 'Backspace' || ev.key === 'Delete') {
            dispatch(SudokuActions.updateCurrentCell(sudokuId, false));
            ev.preventDefault();
        } else if(/^[1-9]$/.test(ev.key)) {
            dispatch(SudokuActions.updateCurrentCell(sudokuId, ev.key));
            ev.preventDefault();
        }
    }, [dispatch, sudokuId]);

    useEffect(() => {
        document.body.addEventListener('keydown', onKeydownEvent);

        return () => {
            document.body.removeEventListener('keydown', onKeydownEvent);
        }
    }, [onKeydownEvent]);

    const onPressCloseModal = () => {
        setShowDoneModal(false);
        setShowLostModal(false);
    }

    const onPressRestart = () => {
        dispatch(SudokuActions.clear(sudokuId));
    }

    return (
        <>
        <Modal onClickCancel={onPressCloseModal} show={showDoneModal} variant="success" title="¡Felicidades!" description="¡Ha completado el sudoku con exito!" cancelText="Cerrar" />
        <Modal onClickCancel={onPressCloseModal} onClickConfirm={onPressRestart} show={showLostModal} confirmText="Reiniciar" variant="danger" title="¡Oh no!" description="Tiene al menos una respuesta incorrecta, por lo que ha perdido. Puede reiniciar el sudoku e intentar de nuevo." cancelText="Cerrar" />
        <div className="md:max-h-700 w-full h-full">
            <SudokuToolbar sudokuId={sudokuId} />
            <div className="flex flex-col md:flex-row">
                <div className="flex-1">
                    <SudokuBoard sudokuId={sudokuId} />
                </div>
                <div className="flex-1 flex flex-col items-center justify-center ">
                    <div className="mt-2">
                        {isLost ? 
                            <div className="text-red-600 bg-red-100 text-center p-3 rounded">
                                <span className="font-medium">Tiene al menos una respuesta incorrecta.</span><br/>
                                Puede reiniciar el sudoku si desea intentar de nuevo.
                            </div>
                        : isDone ?
                            <div className="text-green-600 bg-green-100 text-center p-3 rounded">
                                <span className="font-medium">¡Felicidades!</span><br/>
                                Ha completado el sudoku correctamente.
                            </div>
                        : isPuzzleChecked ?
                            <div className="text-blue-700 bg-blue-100 text-center p-3 rounded">
                                Todo parece estar correcto hasta el momento.
                            </div>
                        : null}
                    </div>
                    <SudokuKeyboard sudokuId={sudokuId} />
                </div>
            </div>
        </div>
        </>
    );
}

export default Sudoku;