import React from 'react';
import { useDispatch } from 'react-redux';

import * as CrosswordActions from '../../redux/actions/crossword';
import useCurrentHint from '../../hooks/useCurrentHint';
import Dropdown from '../Dropdown.jsx';

const CrosswordToolbar = ({ crosswordId }) => {
    const dispatch = useDispatch();
    const currentHint = useCurrentHint(crosswordId);

    const onClickReveal = (type) => {
        dispatch(CrosswordActions.revealAnswer(crosswordId, type));
    }

    const onClickCheck = (type) => {
        dispatch(CrosswordActions.checkAnswer(crosswordId, type));
    }

    return (
        <div className="mt-1 bg-gray-100 shadow rounded-sm px-5 flex flex-row items-center mb-2 sm:mb-4 justify-between">
            <div className="flex-grow">
                <span className="hidden sm:inline font-semibold pr-1">{currentHint.label} </span>     
                <span className="hidden sm:inline font-regular">{currentHint.text}</span>           
            </div>
            <div className="flex flex-row">
                <Dropdown
                    items={[
                        {title: "Letra", onClicked: () => onClickReveal("LETTER")},
                        {title: "Palabra", onClicked: () => onClickReveal("WORD")},
                    ]}
                    title="Revelar"
                />
                <Dropdown
                    items={[
                        {title: "Letra", onClicked: () => onClickCheck("LETTER")},
                        {title: "Palabra", onClicked: () => onClickCheck("WORD")},
                        {title: "Crucigrama", onClicked: () => onClickCheck("ALL")}
                    ]}
                    title="Comprobar"
                />
            
            </div>
        </div>
    );
}

export default CrosswordToolbar;