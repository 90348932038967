const Modal = ({ show, variant, title, description, confirmText, cancelText, onClickConfirm, onClickCancel }) => {
    // classes must be specified in full without concatentation because of how purgecss works
    let vars = {
      color: 'blue',
      bg100: 'bg-blue-100',
      bg600: 'bg-blue-600',
      bg700: 'bg-blue-700',
      txt600: 'text-blue-600',
      icon: false
    }

    switch(variant) {
      case "danger":
        vars = {
          color: 'red',
          bg100: 'bg-red-100',
          bg600: 'bg-red-600',
          bg700: 'bg-red-700',
          txt600: 'text-red-600',
          icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
        }
        
        break;
      case "success":
        vars = {
          color: 'green',
          bg100: 'bg-green-100',
          bg600: 'bg-green-600',
          bg700: 'bg-green-700',
          txt600: 'text-green-600',
          icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
        }
        break;
      default:
        break;
    }

    if(!show) return null;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                {vars.icon ? 
                <div className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${vars.bg100} ${vars.txt600} sm:mx-0 sm:h-10 sm:w-10`}>
                  {vars.icon}
                </div> : null}
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                    {title}
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              {confirmText ? 
              <button type="button" onClick={onClickConfirm} className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${vars.bg600} text-base font-medium text-white hover:${vars.bg700} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`}>
                {confirmText}
              </button> : null }
              <button type="button" onClick={onClickCancel} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                {cancelText || "Cancelar" }
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Modal;