import * as types from '../actions/actionTypes';

const initialCrosswordState = {
    isDone: false,
    selectHorizontal: true,
    crosswordSize: 15,
    cells: [],
    hints: {},
    selectedIdx: 0,
    selectedAdjacentIdxs: [],
    selectedVerticalHintIdx: 0,
    selectedHorizontalHintIdx: 0
};

const initialState = {
    byId: {},
    isLoading: true,
    relatedCrosswords: []
}

const compareGuess = (answer, guess) => {
    if(answer === "Ñ" && guess === "N") return true;
    return answer === guess;
}

const crosswordReducer = (state = initialState, action) => {
    switch(action.type) {
        case types.CROSSWORD_SET_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case types.CROSSWORD_SET_DATA:
            return {
                ...state,
                isLoading: false,
                byId: {
                    ...state.byId,
                    [action.payload._id]: {
                        ...initialCrosswordState,
                        id: action.payload._id,
                        slug: action.payload.slug,
                        publishDate: action.payload.publishDate,
                        crosswordSize: action.payload.data.size,
                        cells: action.payload.data.cells,
                        hints: {
                            horizontal: action.payload.data.hints.horizontal,
                            vertical: action.payload.data.hints.vertical
                        }
                    }
                }
            };
        case types.CROSSWORD_SET_RELATED:
            return {
                ...state,
                relatedCrosswords: action.payload.map(cwd => ({
                    ...cwd,
                    isDone: state.byId[cwd._id] && state.byId[cwd._id].isDone
                }))
            }
        case types.CROSSWORD_SELECT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.crosswordId]: {
                        ...state.byId[action.crosswordId],
                        selectedIdx: action.payload.selectedIdx,
                        selectedAdjacentIdxs: action.payload.selectedAdjacentIdxs,
                        selectedVerticalHintIdx: action.payload.selectedVerticalHintIdx,
                        selectedHorizontalHintIdx: action.payload.selectedHorizontalHintIdx
                    }
                }   
            }
        case types.CROSSWORD_SET_DIR:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.crosswordId]: {
                        ...state.byId[action.crosswordId],
                        selectHorizontal: action.payload
                    }
                }
            }
        case types.CROSSWORD_SET_CELL:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.crosswordId]: {
                        ...state.byId[action.crosswordId],
                        cells: state.byId[action.crosswordId].cells.map((cell, idx) => (
                            idx === action.payload.idx ? {
                                ...state.byId[action.crosswordId].cells[idx],
                                guess: action.payload.value,
                                isChecked: false
                            } : cell
                        ))
                    }
                }
            }
        case types.CROSSWORD_REVEAL:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.crosswordId]: {
                        ...state.byId[action.crosswordId],
                        cells: state.byId[action.crosswordId].cells.map((cell, idx) => (
                            action.payload.cellsToReveal.includes(idx) ? {
                                ...state.byId[action.crosswordId].cells[idx],
                                isRevealed: true,
                                guess: state.byId[action.crosswordId].cells[idx].answer
                            } : cell
                        ))
                    }
                }
            }
        case types.CROSSWORD_CHECK:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.crosswordId]: {
                        ...state.byId[action.crosswordId],
                        cells: state.byId[action.crosswordId].cells.map((cell, idx) => (
                            action.payload.cellsToCheck.includes(idx) ? {
                                ...state.byId[action.crosswordId].cells[idx],
                                isChecked: true,
                                isRevealed: compareGuess(
                                    state.byId[action.crosswordId].cells[idx].answer, 
                                    state.byId[action.crosswordId].cells[idx].guess
                                ),
                            } : cell
                        ))
                    }
                }
            }
        case types.CROSSWORD_UPDATE_COMPLETION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.crosswordId]: {
                        ...state.byId[action.crosswordId],
                        isDone: state.byId[action.crosswordId].cells.filter(c => c.isRevealed || c.isBlank).length === state.byId[action.crosswordId].cells.length
                    }
                }
            }
        default:
            return state;
    }
}

export default crosswordReducer;