import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as CrosswordActions from '../../redux/actions/crossword';
import CrosswordCell from './CrosswordCell';
import ResponsiveSquare from '../common/ResponsiveSquare';

const CrosswordBoard = ({ crosswordId }) => {
    const dispatch = useDispatch();

    const crosswordCells = useSelector(state => state.crossword.byId[crosswordId].cells);
    const activeCell = useSelector(state => state.crossword.byId[crosswordId].selectedIdx);
    const selectedCells = useSelector(state => state.crossword.byId[crosswordId].selectedAdjacentIdxs);

    const onClickCell = (idx) => {
        if(activeCell === idx) {
            dispatch(CrosswordActions.toggleDirection(crosswordId));
        } else {
            dispatch(CrosswordActions.selectCell(crosswordId, idx));
        }
    }

    return (
        <ResponsiveSquare>
            <div className="bg-black rounded grid gap-0.5 border-black border-4 w-full h-full grid-cols-15 grid-rows-15">
                {crosswordCells.map((cell,i) => 
                    <CrosswordCell
                        key={i}
                        number={cell.number}
                        cellIndex={i}
                        value={cell.guess}
                        isBlank={cell.isBlank}
                        onCellClicked={() => onClickCell(i)}
                        isActive={activeCell === i}
                        isSelected={selectedCells.includes(i)}
                        isRevealed={cell.isRevealed}
                        isChecked={cell.isChecked}
                    />
                )}
            </div>
        </ResponsiveSquare>
    );
}

export default CrosswordBoard;