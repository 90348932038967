import React from 'react';

const SudokuCell = ({ value, onCellClicked, hSep, vSep, isPrefilled, notes, isActive, isChecked, isCorrect }) => {
    return (
        <div
         onClick={onCellClicked}
         className={`h-full w-full flex flex-1 justify-center ${isPrefilled ? 'bg-gray-200' : isActive ? 'bg-blue-200' : 'bg-white'} items-center uppercase sudoku-inner-border ${hSep ? 'sudoku-left-border' : ''} ${vSep ? 'sudoku-top-border' : ''}`} >
            {value ? 
                <div className={`${isChecked && isCorrect ? 'text-blue-500' : isChecked ? 'text-red-500' : 'text-black'} text-base xl:text-3xl lg:text-xl text-center pt-2 font-semibold uppercase flex-1`}>
                    {value}
                </div>    
            :
                <div className="grid w-full h-full text-xxs lg:text-sm text-gray-600 font-medium grid-cols-3 grid-rows-3">
                    {(notes || []).map((val, i) => (
                        <div key={i} className="flex items-center justify-center">{ val ? i+1 : ""}</div>
                    ))}
                </div>
            }
        </div>
    );
}

export default SudokuCell;