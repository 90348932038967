import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as AdminActions from '../../redux/actions/admin';

const Login = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.admin.isLoggedIn);

    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [formVals, setFormVals] = useState({username: '', password: ''});

    const onSubmitForm = async (ev) => {
        ev.preventDefault();
        
        try {
            setIsLoggingIn(true);
            await dispatch(AdminActions.authenticate(formVals.username, formVals.password));
        } catch (errMsg) {
            toast(errMsg, { type: "error" });
        } finally {
            setIsLoggingIn(false);
        }
    }

    const handleChange = (ev) => {
        setFormVals(currentVals => ({
            ...currentVals,
            [ev.target.name]: ev.target.value
        }));
    }

    return isLoggedIn ? <Redirect to="/admin" /> : (
        <div className="max-w-md m-auto my-10">
            <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                tuRecreo Admin
            </h2>
            </div>
            <form className="mt-8 space-y-6" onSubmit={onSubmitForm}>
                <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                        <input value={formVals.username} onChange={handleChange} name="username" type="text" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Usuario"/>
                    </div>
                    <div>
                        <input value={formVals.password} onChange={handleChange} name="password" type="password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Contraseña"/>
                    </div>
                </div>
                <div>
                    <button disabled={isLoggingIn} type="submit" className="disabled:opacity-50 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                        </svg>
                    </span>
                    {isLoggingIn ? "Ingresando..." : "Ingresar"}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Login;