import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Puzzles from './Puzzles';
import NewPuzzle from './NewPuzzle';
import Login from './Login';
import PrivateRoute from '../PrivateRoute';

const Admin = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute exact path={path}>
                <Puzzles />
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/new`} >
                <NewPuzzle />
            </PrivateRoute>
            <Route exact path={`${path}/login`}>
                <Login />
            </Route>
        </Switch>
    );
}

export default Admin;