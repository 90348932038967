import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useCurrentHint = (crosswordId) => {
    const [currentHint, setCurrentHint] = useState({label: '', text: ''});
    const hints = useSelector(state => state.crossword.byId[crosswordId].hints);
    const selectHorizontal = useSelector(state => state.crossword.byId[crosswordId].selectHorizontal);
    const selectedHorizontalHintIdx = useSelector(state => state.crossword.byId[crosswordId].selectedHorizontalHintIdx);
    const selectedVerticalHintIdx = useSelector(state => state.crossword.byId[crosswordId].selectedVerticalHintIdx);

    useEffect(() => {
        let hint;
        if(selectHorizontal) {
            hint = hints.horizontal[selectedHorizontalHintIdx];
        } else {
            hint = hints.vertical[selectedVerticalHintIdx];
        }

        if(!hint) return;

        setCurrentHint({
            label: hint.number + (selectHorizontal ? 'H' : 'V'),
            text: hint.hint
        })
    }, [hints, selectHorizontal, selectedHorizontalHintIdx, selectedVerticalHintIdx]);

    return currentHint;
}

export default useCurrentHint;