import { getAuthHeader, logout } from './auth.service';
import { API_URL_BASE } from './config';

export const createPuzzle = async (puzzleType, formData) => {
    const requestOptions = {
        method: 'POST',
        headers: getAuthHeader(),
        body: formData
    };

    const response = await fetch(`${API_URL_BASE}/admin/puzzle/${puzzleType}`, requestOptions);
    const res = await handleResponse(response);
    return res[puzzleType];
};

export const getPuzzles = async (puzzleType) => {
    const requestOptions = {
        method: 'GET',
        headers: getAuthHeader()
    };

    const response = await fetch(`${API_URL_BASE}/admin/puzzle/${puzzleType}`, requestOptions);
    const res = await handleResponse(response);
    return res[`${puzzleType}s`];
}

export const deletePuzzle = async (puzzleId) => {
    const requestOptions = {
        method: 'DELETE',
        headers: getAuthHeader(),
    };

    const response = await fetch(`${API_URL_BASE}/admin/puzzle/${puzzleId}`, requestOptions);
    await handleResponse(response);
    return true;
};

const handleResponse = (response) => {
    return response.text().then(text => {
        if (!response.ok) {
            if (response.status === 401) {
                logout();
                window.location = '/admin/login';
            }

            const data = text && JSON.parse(text);
            const error = (data && data.msg) || response.statusText;
            return Promise.reject(error);
        }

        const data = text && JSON.parse(text);
        return data;
    })
};