import * as types from '../actions/actionTypes';

const initialSudokuState = {
    isDone: false,
    isLost: false,
    isPuzzleChecked: false,
    cells: [],
    selectedIdx: 0,
    sudokuSize: 9
};

const initialState = {
    byId: {},
    isLoading: true,
    pencilMode: false,
    relatedSudoku: []
}

const sudokuReducer = (state = initialState, action) => {
    switch(action.type) {
        case types.SUDOKU_SET_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case types.SUDOKU_TOGGLE_PENCIL:
            return {
                ...state,
                pencilMode: !state.pencilMode
            };
        case types.SUDOKU_SET_DATA:
            return {
                ...state,
                isLoading: false,
                pencilMode: false,
                byId: {
                    ...state.byId,
                    [action.payload._id]: {
                        ...initialSudokuState,
                        id: action.payload._id,
                        slug: action.payload.slug,
                        publishDate: action.payload.publishDate,
                        cells: action.payload.data.cells,
                        sudokuSize: action.payload.data.size
                    }
                }
            };
        case types.SUDOKU_SET_RELATED:
            return {
                ...state,
                relatedSudoku: action.payload.map(cwd => ({
                    ...cwd,
                    isDone: state.byId[cwd._id] && state.byId[cwd._id].isDone
                }))
            }
        case types.SUDOKU_SELECT:
            return {
                ...state,
                pencilMode: (state.byId[action.sudokuId].cells[action.payload.selectedIdx].notes || []).filter(n=> n===true).length > 0,
                byId: {
                    ...state.byId,
                    [action.sudokuId]: {
                        ...state.byId[action.sudokuId],
                        selectedIdx: action.payload.selectedIdx,
                    }
                }   
            }
        case types.SUDOKU_SET_CELL:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.sudokuId]: {
                        ...state.byId[action.sudokuId],
                        isPuzzleChecked: false,
                        cells: state.byId[action.sudokuId].cells.map((cell, idx) => (
                            idx === action.payload.idx ? {
                                ...state.byId[action.sudokuId].cells[idx],
                                guess: action.payload.value,
                                isChecked: false,
                                notes: new Array(state.byId[action.sudokuId].sudokuSize).fill(false),
                            } : cell
                        ))
                    }
                }
            }
        case types.SUDOKU_TOGGLE_CELL_NOTE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.sudokuId]: {
                        ...state.byId[action.sudokuId],
                        cells: state.byId[action.sudokuId].cells.map((cell, idx) => (
                            idx === action.payload.idx ? {
                                ...cell,
                                guess: undefined,
                                notes: (cell.notes || new Array(state.byId[action.sudokuId].sudokuSize).fill(false)).map((val, idx) => {
                                    return (idx === action.payload.number - 1) ? !Boolean(val) : cell.guess && idx === (cell.guess - 1) ? true : val
                                })
                            } : cell
                        ))
                    }
                }
            }
        case types.SUDOKU_CLEAR_PROGRESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.sudokuId]: {
                        ...state.byId[action.sudokuId],
                        isDone: false,
                        isLost: false,
                        isPuzzleChecked: false,
                        cells: state.byId[action.sudokuId].cells.map((cell) => ({
                            ...cell,
                            isChecked: false,
                            guess: undefined,
                            notes: new Array(state.byId[action.sudokuId].sudokuSize).fill(false)
                        }))
                    }
                }
            }
        case types.SUDOKU_CHECK:
            let allCorrect = true;

            const newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.sudokuId]: {
                        ...state.byId[action.sudokuId],
                        isPuzzleChecked: true,
                        cells: state.byId[action.sudokuId].cells.map((cell) => {
                            const isChecked = Boolean(cell.guess);
                            const isCorrect = isChecked && cell.answer === cell.guess;

                            if(isChecked) allCorrect = allCorrect && isCorrect;
                            
                            return {
                                ...cell,
                                isChecked,
                                isCorrect,
                            }
                        } 
                            
                        )
                    }
                }
            }

            if(action.payload.noFail && !allCorrect) {
                // dont update state to failed/done on auto check
                return state;
            }

            if(!allCorrect) {
                newState.byId[action.sudokuId].isLost = true;
            }

            const filledCells = newState.byId[action.sudokuId].cells.filter(c => c.isPrefilled || c.isChecked).length;
            if(filledCells === state.byId[action.sudokuId].cells.length) {
                newState.byId[action.sudokuId].isDone = true;
            } else {
                newState.byId[action.sudokuId].isDone = false;
            }

            return newState;
        default:
            return state;
    }
}

export default sudokuReducer;