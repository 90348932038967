export const CROSSWORD_FETCH_DATA = 'CROSSWORD_FETCH_DATA';
export const CROSSWORD_SET_LOADING = 'CROSSWORD_SET_LOADING';
export const CROSSWORD_SET_DATA = 'CROSSWORD_SET_DATA';
export const CROSSWORD_SET_RELATED = 'CROSSWORD_SET_RELATED';
export const CROSSWORD_SET_CURRENT = 'CROSSWORD_SET_CURRENT';
export const CROSSWORD_SELECT = 'CROSSWORD_SELECT';
export const CROSSWORD_SET_DIR = 'CROSSWORD_SET_DIR';
export const CROSSWORD_SET_CELL = 'CROSSWORD_SET_CELL';
export const CROSSWORD_REVEAL = 'CROSSWORD_REVEAL';
export const CROSSWORD_CHECK = 'CROSSWORD_CHECK';
export const CROSSWORD_UPDATE_COMPLETION = 'CROSSWORD_UPDATE_COMPLETION';

export const SUDOKU_SET_LOADING = 'SUDOKU_SET_LOADING';
export const SUDOKU_SET_DATA = 'SUDOKU_SET_DATA';
export const SUDOKU_SET_RELATED = 'SUDOKU_SET_RELATED';
export const SUDOKU_SELECT = 'SUDOKU_SELECT';
export const SUDOKU_SET_CELL = 'SUDOKU_SET_CELL';
export const SUDOKU_REVEAL = 'SUDOKU_REVEAL';
export const SUDOKU_CHECK = 'SUDOKU_CHECK';
export const SUDOKU_CLEAR_PROGRESS = 'SUDOKU_CLEAR_PROGRESS';
export const SUDOKU_TOGGLE_PENCIL = 'SUDOKU_TOGGLE_PENCIL';
export const SUDOKU_TOGGLE_CELL_NOTE = 'SUDOKU_TOGGLE_CELL_NOTE';

export const ADMIN_SET_USER = 'ADMIN_SET_USER';
export const ADMIN_SET_LOADING_LIST = 'ADMIN_SET_LOADING_LIST';
export const ADMIN_SET_LIST = 'ADMIN_FETCH_LIST';
