import { getAuthHeader } from './auth.service';
const { API_URL_BASE } = require('./config');

export const getLatest = (puzzleType) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(`${API_URL_BASE}/${puzzleType}`, requestOptions)
        .then(res => res.json())
        .then(data => data);
}

export const getBySlug = (puzzleType, slug) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...getAuthHeader() }
    };

    return fetch(`${API_URL_BASE}/${puzzleType}/${slug}`, requestOptions)
        .then(res => res.json())
        .then(data => data);
}