import * as types from './actionTypes';

import * as puzzleService from '../../services/puzzle.service';

export const fetchLatest = () => {
    return async (dispatch, getState) => {
        dispatch({type: types.CROSSWORD_SET_LOADING});

        const latestData = await puzzleService.getLatest("crossword");
        const crossword = latestData.crossword;

        if(!getState().crossword.byId[crossword._id]) {
            dispatch({type: types.CROSSWORD_SET_DATA, payload: crossword});
            dispatch(selectHint(crossword._id, "HORIZONTAL", 0));
        }

        dispatch({type: types.CROSSWORD_SET_RELATED, payload: latestData.related});

        return crossword;
    }
}

export const fetchBySlug = (slug) => {
    return async (dispatch, getState) => {
        dispatch({type: types.CROSSWORD_SET_LOADING});

        const resData = await puzzleService.getBySlug("crossword", slug);
        const crossword = resData.crossword;

        if(!crossword) return null;

        if(!getState().crossword.byId[crossword._id]) {
            dispatch({type: types.CROSSWORD_SET_DATA, payload: crossword});
            dispatch(selectHint(crossword._id, "HORIZONTAL", 0));
        }

        dispatch({type: types.CROSSWORD_SET_RELATED, payload: resData.related});
        
        return crossword;
    }
}

export const selectCell = (crosswordId, cellIdx) => {
    return (dispatch, getState) => {
        const { crossword: { byId: { [crosswordId]: { crosswordSize, cells, selectHorizontal } } } } = getState();

        if(cellIdx < 0 || cellIdx >= cells.length) return;

        let newSelected = [];

        let step = selectHorizontal ? 1 : crosswordSize;
        let i = cellIdx - step;
        while(i >= 0 && (selectHorizontal ? ((i + step) % crosswordSize !== 0) : true) && !cells[i].isBlank) {
            newSelected.push(i);
            i -= step;
        }

        i = cellIdx + step;
        while(i < cells.length && (selectHorizontal ? (i % crosswordSize !== 0) : true) && !cells[i].isBlank) {
            newSelected.push(i);
            i += step;
        }
        
        dispatch({
            crosswordId,
            type: types.CROSSWORD_SELECT, 
            payload: {
                selectedIdx: cellIdx, 
                selectedAdjacentIdxs: newSelected,
                selectedHorizontalHintIdx: cells[cellIdx].horizontalHintIdx,
                selectedVerticalHintIdx: cells[cellIdx].verticalHintIdx,
            }
        });

        if((selectHorizontal && cells[cellIdx].horizontalHintIdx === undefined)
            || (!selectHorizontal && cells[cellIdx].verticalHintIdx === undefined)) {
                dispatch(toggleDirection(crosswordId));
            }
    }
}

export const selectHint = (crosswordId, type, hintIdx) => {
    return (dispatch, getState) => {
        if(type !== 'HORIZONTAL' && type !== 'VERTICAL') return;

        const { crossword: { byId: { [crosswordId]: { hints } } } } = getState();
        
        dispatch({ 
            crosswordId,
            type: types.CROSSWORD_SET_DIR, 
            payload: type === 'HORIZONTAL' 
        });
        dispatch(selectCell(crosswordId, hints[type.toLowerCase()][hintIdx].cellIdx));
    }
}

export const toggleDirection = (crosswordId) => {
    return (dispatch, getState) => {
        const { crossword: { byId: { [crosswordId]: { selectHorizontal, selectedIdx } } } } = getState();
        dispatch({ 
            crosswordId,
            type: types.CROSSWORD_SET_DIR, 
            payload: !selectHorizontal 
        });
        dispatch(selectCell(crosswordId, selectedIdx));
    }
}

export const updateCurrentCell = (crosswordId, value) => {
    return (dispatch, getState) => {
        const { crossword: { byId: { [crosswordId]: { selectedIdx, cells } } } } = getState();

        if(cells[selectedIdx].isRevealed) return;

        dispatch({
            type: types.CROSSWORD_SET_CELL,
            crosswordId,
            payload: {
                value: value && value.toUpperCase(),
                idx: selectedIdx
            }
        });

        const { crossword: { byId: { [crosswordId]: { cells: newCells } } } } = getState();
        if(newCells.filter(c => c.guess || c.isBlank).length === newCells.length) {
            dispatch(checkAnswer(crosswordId, "ALL"));
        }
    }
}

export const selectNextCell = (crosswordId, currentCellIdx, visited=[], forceHorizontal, selectGuessed) => {
    return (dispatch, getState) => {
        const { crossword: { byId: { [crosswordId]: {selectHorizontal, crosswordSize, selectedIdx, cells} } } } = getState();
        const cellIdx = currentCellIdx !== undefined ? currentCellIdx : selectedIdx;

        const actuallyHorizontal = forceHorizontal === undefined ? selectHorizontal : forceHorizontal;
        const step = actuallyHorizontal ? 1 : crosswordSize;
        const nextCellIdx = cellIdx + step;

        const isOutOfBounds = nextCellIdx > crosswordSize*crosswordSize - 1;
        const isLastColumn = nextCellIdx % crosswordSize === 0;

        if(selectGuessed && isOutOfBounds) return;
        if(visited.includes(nextCellIdx)) return;

        if(!selectGuessed && (isOutOfBounds || (actuallyHorizontal && isLastColumn) || cells[nextCellIdx].isBlank)) {
            return dispatch(selectNextHint(crosswordId, cellIdx, visited));
        }

        if(cells[nextCellIdx].isBlank || (!selectGuessed && cells[nextCellIdx].guess)) {
            visited.push(nextCellIdx);
            return dispatch(selectNextCell(crosswordId, nextCellIdx, visited, forceHorizontal, selectGuessed));
        }

        dispatch(selectCell(crosswordId, nextCellIdx));
    }
}

export const selectNextHint = (crosswordId, currentCellIdx, visited=[]) => {
    return (dispatch, getState) => {
        const { crossword: { byId: { [crosswordId]: { cells, hints, selectHorizontal } } } } = getState();
    
        const currentCell = cells[currentCellIdx];
        if(!currentCell) return;

        const dirIdxs = {
            horizontal: currentCell.horizontalHintIdx,
            vertical: currentCell.verticalHintIdx
        }

        const dir = selectHorizontal ? 'horizontal' : 'vertical';
        const totalHints = hints[dir].length;

        let dirHintIdx = dirIdxs[dir];
        if(dirHintIdx === totalHints - 1) {
            dirHintIdx = 0;
        } else {
            dirHintIdx ++;
        }
    
        const cellIdx = hints[dir][dirHintIdx].cellIdx;

        if(visited.includes(cellIdx)) return;
        visited.push(cellIdx);

        if(cells[cellIdx].guess) {
            dispatch(selectNextCell(crosswordId, cellIdx, visited));
        } else {
            dispatch(selectCell(crosswordId, cellIdx));
        }
    }
}

export const selectPreviousCell = (crosswordId, currentCellIdx, forceHorizontal) => {
    return (dispatch, getState) => {
        const { crossword: { byId: { [crosswordId]: { selectHorizontal, cells, crosswordSize, selectedIdx } } } } = getState();
        const cellIdx = currentCellIdx || selectedIdx;

            let step = (forceHorizontal === true || (forceHorizontal === undefined && selectHorizontal)) ? 1 : crosswordSize;

            let nextCellIdx = cellIdx - step;
            if(nextCellIdx < 0) return;
        
            if(cells[nextCellIdx].isBlank) {
                return dispatch(selectPreviousCell(crosswordId, nextCellIdx, forceHorizontal));
            }
        
            dispatch(selectCell(crosswordId, nextCellIdx));
    }
}

export const pressBackspace = (crosswordId, currentCellIdx) => {
    return (dispatch, getState) => {
        const { crossword: { byId: { [crosswordId]: { cells, selectedIdx } } } } = getState();

        if(!currentCellIdx && cells[selectedIdx].guess) {
            return dispatch(updateCurrentCell(crosswordId, false));
        }

        dispatch(selectPreviousCell(crosswordId, currentCellIdx));
        dispatch(updateCurrentCell(crosswordId, false));
    }   
}

export const revealAnswer = (crosswordId, type="LETTER") => {
    return (dispatch, getState) => {
        const { crossword: { byId: { [crosswordId]: { selectedAdjacentIdxs, selectedIdx, cells } } } } = getState();
        
        let cellsToReveal = [];
        switch(type) {
            case "LETTER":
                cellsToReveal.push(selectedIdx);
                break;
            case "WORD":
                cellsToReveal.push(selectedIdx, ...selectedAdjacentIdxs);
                break;
            case "ALL":
                cellsToReveal = cells.map((_, idx) => idx);
                break;
            default:
                return;
        }
        
        dispatch({
            type: types.CROSSWORD_REVEAL,
            crosswordId,
            payload: {
                cellsToReveal
            }
        });

        dispatch(checkCompletion(crosswordId));
    }
}

export const checkAnswer = (crosswordId, type="LETTER") => {
    return (dispatch, getState) => {
        const { crossword: { byId: { [crosswordId]: { selectedAdjacentIdxs, selectedIdx, cells } } } } = getState();
        
        let cellsToCheck = [];
        switch(type) {
            case "LETTER":
                cellsToCheck.push(selectedIdx);
                break;
            case "WORD":
                cellsToCheck.push(selectedIdx, ...selectedAdjacentIdxs);
                break;
            case "ALL":
                cellsToCheck = cells.map((_, idx) => idx);
                break;
            default:
                return;
        }
        
        dispatch({
            type: types.CROSSWORD_CHECK,
            crosswordId,
            payload: {
                cellsToCheck
            }
        });

        dispatch(checkCompletion(crosswordId));
    }
}

export const checkCompletion = (crosswordId) => {
    return {
        type: types.CROSSWORD_UPDATE_COMPLETION,
        crosswordId
    }
}