import useCurrentHint from '../../hooks/useCurrentHint';

const CrosswordHintBar = ({ crosswordId }) => {
    const currentHint = useCurrentHint(crosswordId);

    return (
        <div className="bg-blue-100 p-2 my-2 rounded-md text-sm text-center">
            <span className="font-semibold">{currentHint.label} </span>     
            <span className="font-regular">{currentHint.text}</span>             
        </div>
    );
}

export default CrosswordHintBar;