import React, { useEffect } from 'react';

const MediaNetAd = ({ divId, size }) => {
    useEffect(() => {
      if (typeof window !== "undefined") {
        try {
          window._mNHandle.queue.push(function () {
            window._mNDetails.loadTag(divId, size, divId)
          })
        } catch (error) {}
      }
    }, [divId, size]);
  
    return <div id={divId} />
  }

export default MediaNetAd;