import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Datetime from 'react-datetime';
import moment from 'moment';

import * as AdminActions from '../../redux/actions/admin';

const NewCrossword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [puzzleType, setPuzzleType] = useState('crossword');
  const [publishDate, setPublishDate] = useState(moment().add(1, 'day').hours(1).minutes(0).seconds(0));
  const [dataFile, setDataFile] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const { defaultType } = useLocation();

  useEffect(() => {
    if(defaultType) {
      setPuzzleType(defaultType);
    }
    
  }, [defaultType]);

  const onChangeDate = (value) => {
    setPublishDate(value);
  }

  const onChangeFile = (ev) => {
    setDataFile(ev.target.files[0]);
  }

  const onChangeType = (ev) => {
    setPuzzleType(ev.target.value);
  }

  const onSubmitForm = async (ev) => {
    ev.preventDefault();

    if(!dataFile || !publishDate) return;

    const formData = new FormData();
    formData.append("crosswordDataFile", dataFile, dataFile.name);
    formData.append("publishDate", publishDate.toISOString());

    setIsUploading(true);
    const isSuccess = await dispatch(AdminActions.uploadPuzzle(puzzleType, formData));
    if(isSuccess) {
      toast('Subido exitosamente!', {type: "success"});
      history.push('/admin', {defaultType: puzzleType});
    } else {
      setIsUploading(false);

    }
  }

  return (
    <div className="max-w-md md:col-span-2 m-auto my-10">
      <h1 className="text-xl font-semibold mb-5">Subir nuevo</h1>
      <form onSubmit={onSubmitForm}>
        <div className="shadow sm:rounded-md">
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

              <div>
                <label className="block text-sm font-medium text-gray-700">Tipo</label>
                <select value={puzzleType} onChange={onChangeType} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <option value="crossword">Crucigrama</option>
                  <option value="sudoku">Sudoku</option>
                </select>
              </div>

              <div>
              <label className="block text-sm font-medium text-gray-700">
                Fecha de publicación
              </label>
              <div className="mt-2">
                <Datetime 
                  value={publishDate} 
                  onChange={onChangeDate} 
                  input={true} 
                  inputProps={{
                    required: true,
                    className: "mt-1 focus:ring-indigo-500 outline-none focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border py-2 px-3"
                  }}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Archivo excel
              </label>
              <div className="mt-2 flex items-center">
                <input type="file" onChange={onChangeFile} accept=".xlsx" required/>
              </div>
            </div>

          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button disabled={isUploading} type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50">
              {isUploading ? "Subiendo..." : "Subir"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default NewCrossword;