import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import SudokuGame from '../components/Sudoku';
import * as SudokuActions from '../redux/actions/sudoku';

const Sudoku = () => {
    const dispatch = useDispatch();
    const [sudoku, setSudoku] = useState();
    const related = useSelector(state => state.sudoku.relatedSudoku);
    const { slug } = useParams();

    useEffect(() => {
        (async () => {
            let sudokuRes;

            if(slug) {
                sudokuRes = await dispatch(SudokuActions.fetchBySlug(slug));
            } else {
                sudokuRes = await dispatch(SudokuActions.fetchLatest());
            }
            
            setSudoku(sudokuRes);
            window.scrollTo(0,0);
        })()
    }, [dispatch, slug]);


    if(sudoku === undefined) return null;
    if(sudoku === null) return <Redirect to="/404" />

    const formattedDate = moment(sudoku.publishDate).format('LL');
    return (
        <> 
        <Helmet>
            <title>Sudoku {formattedDate} - TuRecreo</title>
        </Helmet>

        <div className="p-8 text-center">
            <h1 className="text-3xl pb-2 font-bold text-gray-900">
                Sudoku del dia
            </h1>
            <h3 className="text-lg  font-medium text-gray-500">
                {formattedDate}
            </h3>
        </div>
        <SudokuGame sudokuId={sudoku._id} />
        <div className="my-8">
            <h1 className="text-2xl pb-4 font-bold text-gray-900">
                Últimos Sudoku
            </h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
                {related.map(item => (
                    <Link key={item._id} to={`/sudoku/${item.slug}`} className="shadow-md text-white bg-gray-800 rounded-md flex hover:opacity-90 justify-between">
                        <div className="flex-grow p-4">
                            <h3 className="text-lg font-medium">{moment(item.publishDate).format('LL')}</h3>
                            <div className={`inline-flex items-center ${item.isDone ? "text-green-300" : "text-gray-400"}`}>
                                {item.isDone ?
                                    <>
                                    <span className="pr-1">Finalizado</span> 
                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </>
                                : 
                                    <>
                                    <span className="pr-1">Pendiente</span> 
                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </>
                                }
                                
                            </div>
                        </div>
                        <div className="bg-gray-600 inline-flex px-4 rounded-md rounded-l-none items-center">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
        </>
    );
}

export default Sudoku;