import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import * as AdminActions from '../../redux/actions/admin';

import Modal from '../../components/Modal';

const typeMap = {
    crossword: {
        plural: 'crucigramas',
        singular: 'crucigrama',
        route: 'crucigramas'
    },
    sudoku: {
        plural: 'sudokus',
        singular: 'sudoku',
        route: 'sudoku'
    }
}

const Crosswords = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.admin.isLoading);
    const puzzles = useSelector(state => state.admin.puzzles);
    
    const [puzzleType, setPuzzleType] = useState();
    const [showModal, setShowModal] = useState(false);
    const [modalCrosswordId, setModalCrosswordId] = useState(undefined);

    const { state : locationState } = useLocation();

    useEffect(() => {
        if(locationState && locationState.defaultType) {
            setPuzzleType(locationState.defaultType);
        } else {
            setPuzzleType('crossword');
        }
        
    }, [locationState]);

    useEffect(() => {
        if(!puzzleType) return;
        dispatch(AdminActions.fetchPuzzles(puzzleType));
    }, [dispatch, puzzleType]);

    if(isLoading) return <div>Loading...</div>

    const onClickDelete = (puzzleId) => {
        setShowModal(true);
        setModalCrosswordId(puzzleId);
    }

    const onCancelModal = () => {
        setShowModal(false);
    }

    const onConfirmDelete = () => {
        dispatch(AdminActions.deletePuzzle(puzzleType, modalCrosswordId));
        setShowModal(false);
    }

    const onChangePuzzleType = (ev) => {
        setPuzzleType(ev.target.value);
    }

    if(!puzzleType) return null;

    return (
        <div className="p-10">
            <Modal 
                show={showModal} 
                variant="danger"
                title={`Eliminar ${typeMap[puzzleType].singular}`}
                description={`Está seguro que desea eliminar este ${typeMap[puzzleType].singular}? Esta acción no puede ser reversada.`}
                confirmText="Eliminar"
                onClickConfirm={onConfirmDelete}
                onClickCancel={onCancelModal}
            />

            <div className="mb-4">
            <label className="text-sm font-medium text-gray-700 mr-4">Tipo:</label>
                <select value={puzzleType} onChange={onChangePuzzleType} className="mt-1 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value="crossword">Crucigramas</option>
                    <option value="sudoku">Sudokus</option>
                </select>
            </div>
            

            

            <div className="flex flex-col sm:flex-row justify-between items-center">
                <h1 className="text-3xl font-semibold mb-5 capitalize">{typeMap[puzzleType].plural}</h1>
                <Link to={{
                    pathname: '/admin/new',
                    defaultType: puzzleType
                }} className="justify-center py-2 my-4 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Nuevo {typeMap[puzzleType].singular}</Link>
            </div>
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr>
                                <th scope="col" className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Fecha de publicación
                                </th>
                                <th scope="col" className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Enlace
                                </th>
                                <th scope="col" className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Estatus
                                </th>
                                <th scope="col" className="px-6 py-3 bg-gray-50">
                                    <span className="sr-only">Eliminar</span>
                                </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {puzzles.map((item) => (
                                    <tr key={item._id}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">{new Date(item.publishDate).toLocaleString()}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <Link to={`/${typeMap[puzzleType].route}/${item.slug}`} className="text-indigo-600 hover:text-indigo-900">/{typeMap[puzzleType].route}/{item.slug}</Link>
                                        </td>

                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${item.status === "ACTIVE" ? "bg-green-100 text-green-800" : item.status === "PENDING" ? "bg-blue-100 text-blue-800" : "bg-gray-100 text-gray-800"}`}>
                                                {item.status === "ACTIVE" ? "Activo" : item.status === "PENDING" ? "Pendiente" : "Expirado"}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right">
                                            <button onClick={() => onClickDelete(item._id)} className="text-red-600 hover:text-red-900 text-sm font-medium focus:ring-0 focus:outline-none">Eliminar</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
        
  
}

export default Crosswords;