import { API_URL_BASE } from './config';

const TOKEN_STORAGE_KEY = "recreo_auth";

export const login = (username, password) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${API_URL_BASE}/admin/auth`, requestOptions)
        .then(handleResponse)
        .then(res => {
            if(res && res.token) {
                localStorage.setItem(TOKEN_STORAGE_KEY, res.token);
            }

            return res.user;
        });
}

export const checkToken = async () => {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);

    if(!token) {
        return false;
    }

    const requestOptions = {
        method: 'GET',
        headers: getAuthHeader()
    };

    let response = await fetch(`${API_URL_BASE}/admin/auth/check`, requestOptions);

    if(response.status !== 401) {
        const text = await response.text();
        return text && JSON.parse(text).user;
    }

    logout();
    return false;
}

export const logout = () => {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
}

export const getAuthHeader = () => {
    let token = localStorage.getItem(TOKEN_STORAGE_KEY);
    return {'Authorization': `Bearer ${token}`};
}

const handleResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }

            const error = (data && data.msg) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    })
}
