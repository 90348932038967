import React from 'react';

const NotFound = () => {
    return (
        <div className="p-10 text-center">
            <h1 className="text-2xl font-semibold pb-2">Página no encontrada</h1>
            <p>Favor revisar la direccion ingresada e intente de nuevo.</p>
        </div>
    )
}

export default NotFound;